import { TMApi } from '@web/services/tmapi';
import { AdminActivityNames, AuthActivityNames } from '@core/activities';
import { CreateAdminConfig } from '@core/auth';
import { createActivity } from '@web/features/activities/create-activity';
import { TILTSwal } from '@web/services/swal';
import { useRouter } from 'next/router';
import * as React from 'react';
import { AuthDispatchContext, AuthStateContext } from './auth.context';
import { AuthStatus, AuthTypes } from './auth.state';

/**
 *
 * # useAuth
 *
 * A hook to provides access to {@link AuthStateContext} state and actions.
 * Must be a child of {@link AuthStateContext.Provider }.
 *
 * @export
 * @return {[
 *   state,
 *   { signIn, signUp, signOut, resetPassword, verifyEmail, createAdmin },
 * ]}
 */
export function useAuth() {
  const router = useRouter();

  const state = React.useContext(AuthStateContext);
  const dispatch = React.useContext(AuthDispatchContext);

  const tmapi = new TMApi();

  if (
    typeof state === 'undefined' ||
    !state ||
    typeof dispatch === 'undefined' ||
    !dispatch
  ) {
    throw new Error(
      'useAuth must be used inside of a `AuthStateContext.Provider`. Please wrap this component in an `AuthStateContext.Provider` component',
    );
  }

  const signUp = async (
    email: string,
    password: string,
    redirectTo = '/',
    magic?: string,
    staySignedIn = false,
  ) => {
    const utmParams: Record<string, string> = {};
    const ss = window.sessionStorage;

    ['utm_source', 'utm_campaign'].forEach((utm_key) => {
      const v = ss.getItem(utm_key);
      if (v) {
        utmParams[utm_key] = v;
      }
    });

    await tmapi
      .createUserAccount(email, password, utmParams, magic, staySignedIn)
      .then((_) => {
        return TMApi.restoreLogin(dispatch);
      });
    if (redirectTo) {
      await router.push(redirectTo);
    }
    router.reload();
  };

  const signIn = async (
    email: string,
    password: string,
    shouldRemember = false,
    redirectTo: string | null = '/',
  ) => {
    return TMApi.signIn(email, password, shouldRemember).then((_) => {
      return TMApi.restoreLogin(dispatch).then((user) => {
        dispatch({
          type: AuthTypes.ON_AUTH_FULFILLED,
          payload: { user },
        });
        if (redirectTo) {
          return router.push(redirectTo);
        }
        return true;
      });
    });
  };

  const signOut = async () => {
    if (!state.user?.id) {
      throw new Error('No user found. Please login.');
    }

    await createActivity({
      uid: state.user.id,
      config: { name: AuthActivityNames.SIGN_OUT },
    });

    await tmapi.logout();
    dispatch({ type: AuthTypes.ON_AUTH_RESET });

    await router.push('/');
    return router.reload();
  };

  /**
   * # changeEmail
   *
   * Allows a user to change their email
   */
  const changeEmail = async (_email: string) => {
    await TILTSwal.fire({
      title: 'Error',
      text: 'Sorry, the function is currently unavailable.',
      icon: 'error',
    }); // todo(ts)
    // await auth.currentUser?.updateEmail(email);
    // await TILTSwal.fire({
    //   icon: 'success',
    //   title: <p css={{ textAlign: 'center' }}>Successfull</p>,
    //   html: (
    //     <div css={{ textAlign: 'center' }}>
    //       <p>Your email has been successfully changed</p>
    //     </div>
    //   ),
    // });
  };

  /**
   * # resetPassword
   *
   * Sends an reset email to the specified email address if
   */
  const resetPassword = async (email: string) => {
    await tmapi.resetPassword(email);

    await TILTSwal.fire({
      icon: 'success',
      title: <p css={{ textAlign: 'center' }}>Reset Email Sent!</p>,
      html: (
        <div css={{ textAlign: 'center' }}>
          <p>Check your email to reset your password</p>
        </div>
      ),
    });
  };

  /**
   * # verifyEmail
   *
   * Sends a request for a user to verify their email address.
   */
  const verifyEmail = async () => {
    TILTSwal.fire({
      title: 'Error',
      text: 'Currently unavailable',
      icon: 'error',
    });

    if (!state.user?.id) {
      throw new Error('No user found. Please login.');
    }

    await createActivity({
      uid: state.user.id,
      config: { name: AuthActivityNames.VERIFY_EMAIL },
    });

    await TILTSwal.fire({
      icon: 'success',
      title: <p css={{ textAlign: 'center' }}>Email verification Sent!</p>,
      html: (
        <div css={{ textAlign: 'center' }}>
          <p>Check your email to verify your email</p>
        </div>
      ),
    });
  };

  /**
   * # createAdmin
   *
   * Calls our `onCreateAdmin` callable function to create an admin user
   *  permissions granting
   *
   */
  const createAdmin = async (config: CreateAdminConfig) => {
    throw new Error('Currently unavailable'); //todo(ts)
    const onCreateAdmin = functions.httpsCallable('default-auth-onCreateAdmin');

    const { data } = await onCreateAdmin(config);

    if (!state.user?.id) {
      throw new Error('No user found. Please login.');
    }

    await createActivity({
      uid: state.user.id,
      config: { name: AdminActivityNames.CREATE_ADMIN, details: config },
    });

    await TILTSwal.fire({
      icon: 'success',
      title: <p css={{ textAlign: 'center' }}>Admin User Created!</p>,
      html: (
        <div css={{ textAlign: 'center' }}>
          <p>{JSON.stringify(data, null, 2)}</p>
        </div>
      ),
    });
  };

  return [
    state,
    {
      signIn,
      signUp,
      signOut,
      changeEmail,
      resetPassword,
      verifyEmail,
      createAdmin,
    },
  ] as const;
}
